import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

import CommonService from "../../services/common.service";
import ToastComponent from "../Common/TaostComponent";
import { Link } from "react-router-dom";
const PaidModal = (props) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const gameDetails = JSON.parse(localStorage.getItem("club_details"));
  const [isChecked, setIsChecked] = useState(true);
  const handleDeleteClick = (event) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to mark as paid?"
    );
    if (shouldDelete) {
      if (event.target.checked) {
        // Perform the deletion logic here
        CommonService.markAsPaidOrUnPaid(userDetails?.id, 1, 2, gameDetails?.id)
          // .then((response) => response.json())
          .then((result) => {
            ToastComponent(result.message, "success");
          });
        setIsChecked(false);
      } else {
        setIsChecked(true);
      }
    } else {
      console.log("Cancelled deletion.");
    }
  };
  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
      >
        <Modal.Header>Entry Fee Details</Modal.Header>
        <Modal.Body>
          <div className="card-body">
            <h5 className="card-title">
              <strong>Entry Fee</strong>
            </h5>
            <p className="card-text">
              {props.dashboardData?.entry_price_symbol}
              {props.dashboardData?.entry_price}
            </p>
          </div>
          <div className="card-body">
            <h5 className="mt-5">
              <strong>Fee Information</strong>
            </h5>
            <p className="card-text">
              {ReactHtmlParser(props.dashboardData?.entry_fee_info)}
            </p>
          </div>
          <div className="paidchesdsck">
            {!props.userPayment && (
              <>
                <h5>
                  <strong>Inform Admin</strong>
                </h5>
                <input
                  type="checkbox"
                  onClick={handleDeleteClick}
                  id="checkbox"
                />
                &nbsp;&nbsp;
                <label className="form-check-label" htmlFor="checkbox">
                  I have paid
                </label>
              </>
            )}
            {props.userPayment?.is_paid === 0 && (
              <>
                <h5>
                  {" "}
                  <strong>Payment Status</strong>
                </h5>
                <label className="form-check-label" htmlFor="checkbox">
                  Pending
                </label>
              </>
            )}
            {props.userPayment && props.userPayment?.is_paid == 1 && (
              <>
                <h5>
                  <strong>Inform Admin</strong>
                </h5>
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={true}
                  disabled={true}
                />
                &nbsp;&nbsp;
                <label className="form-check-label" htmlFor="checkbox">
                  I have paid
                </label>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="/images/logo.png" alt="img" />
          </div>
          <div className="">
            <Link
              className="border-btn"
              onClick={() => props.setVisible(false)}
            >
              Close
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaidModal;
