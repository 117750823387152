import { agent } from "../utils/agent";
const API_URL = process.env.REACT_APP_API_URL;

const getUserProfile = async (rd = 0) => {
  const response = await agent.get(API_URL + `user-profile/${rd}`);
  return response.data;
};
const getUserProfileData = async (userSlug) => {
  const response = await agent.get(API_URL + `getUserProfileData/${userSlug}`);
  return response.data;
};

const getFollowedPlayer = async (slug = "", pageNumber = 1) => {
  const response = await agent.get(
    API_URL + `followed-players/${slug}?page=${pageNumber}`
  );
  return response.data;
};
const updateProfile = async (formData) => {
  const response = await agent.post(API_URL + `updateProfile`, formData);
  return response.data;
};
const getPlayerUserProfile = async (gameId) => {
  const response = await agent.get(API_URL + `getPlayerUserProfile/${gameId}`);
  return response.data;
};

const UserProfile = {
  getUserProfile,
  getFollowedPlayer,
  updateProfile,
  getUserProfileData,
  getPlayerUserProfile,
};

export default UserProfile;
