import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Component/Common/Loader";
import Header from "./Component/Element/Header";
import Footer from "./Component/Element/Footer";

import { useEffect } from "react";
const MainPage = lazy(() => import("./MainPage"));
const TRACKING_ID = "G-7QFQ19RQ45"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);
const App = (props) => {
  const [gameMenuLink, setGameMenuLink] = useState("gamemenu-link");
  const [gameMenuOverlay, setGameMenuOverlay] = useState("gamemenu-overlay");

  const removeGameMenu = () => {
    setGameMenuLink("gamemenu-link");
    setGameMenuOverlay("gamemenu-overlay");
  };

  const gameMenuClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setGameMenuLink("gamemenu-link open");
    setGameMenuOverlay("gamemenu-overlay active");
  };

  const handleDocumentClick = (event) => {
    const gameMenu = document.getElementById("gamemenu");
    if (gameMenu && !gameMenu.contains(event.target)) {
      removeGameMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === "production";

    if (isProduction) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />

      <Suspense fallback={<Loader />}>
        <Header />
        <MainPage
          gameMenuLink={gameMenuLink}
          gameMenuOverlay={gameMenuOverlay}
          gameMenuClick={gameMenuClick}
          removeGameMenu={removeGameMenu}
        />
        <Footer
          gameMenuLink={gameMenuLink}
          gameMenuOverlay={gameMenuOverlay}
          gameMenuClick={gameMenuClick}
          removeGameMenu={removeGameMenu}
        />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
