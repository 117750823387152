import React from "react";
import { Modal } from "react-bootstrap";
import Stripe from "../Stripe/Stripe";
const PaymentModal = (props) => {
  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
      >
        <Modal.Header>Payment  </Modal.Header>
        <Modal.Body className="mb-3">
          {" "}
          <Stripe
            setVisible={props.setVisible}
            activationInfo={props.activationInfo}
            setActivationInfo={props.setActivationInfo}
            amount={props.amount}
            type={props.type}
            noOfFixture={props.noOfFixture}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentModal;
