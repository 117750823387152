import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
const Index = (props) => {
  const redirectToGamePass = (e) => {
    e.preventDefault();
    window.location.href = "/promembership";
  };
  return (
    <>
      <Modal
        show={props.showGamePass}
        onHide={props.handleCloseGamePass}
        size="lg"
        id="gamepass"
      >
        <figure>
          <img src="/images/popupbanner.jpg" alt="img" />
        </figure>
        <div className="gamepass-detail">
          <h2>Would you like to Buy MCT PRO Membership?</h2>
          <p>
            MyClubtap 'PRO' Membership is your premium access, providing you
            with additional access to view additional game stats and information
            needed to enhance the performance of your fantasy team and support
            the development of MyClubtap! It provides a boost to your decision
            making and your chance to win fantasy games!
          </p>
          <p>Check out all our key features for more information!</p>
          <Link onClick={redirectToGamePass} className="btn btn-primary">
            Buy MCT PRO!
          </Link>
        </div>

        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="../images/logo.png" alt="img" />
          </div>
          <div>
            <Link className="border-btn" onClick={props.handleCloseGamePass}>
              <i className="fas fa-times"></i>
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;
